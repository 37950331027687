.insurance-claim-container {
    background-color: #f4f4f4; /* Light grey background */
}

.insurance-claim-container .insurance-claim-row {
    // margin-bottom: 15px; /* Spacing between rows */
}

.insurance-claim-container .insurance-claim-col {
    padding: 10px;
}
.insurance-claim-container .p-multiselect{
    width:100%;
    height: 40px;
}

.insurance-claim-container .p-multiselect .p-multiselect-label.p-placeholder {
    padding: 6px;
}
.insurance-claim-container .p-multiselect .p-multiselect-label{
    padding: 4px;
}
.insurance-claim-container .filter-label {
    display: block; 
    margin-bottom: 5px; 
    font-weight: bold;
}

.insurance-claim-container .filter-dropdown, 
.insurance-claim-container .filter-date-picker, 
.insurance-claim-container .filter-search {
    width: 100%; 
}

.insurance-claim-container .filter-dropdown .p-dropdown,
.insurance-claim-container .filter-dropdown .dropdown {
    width: 100%; 
    max-width: 250px; 
    height: 38px;
}

.insurance-claim-container .filter-date-picker .ant-picker {
    width: 100%; 
    max-width: 300px; 
}

.insurance-claim-container .filter-search input {
    width: 100%; 
    max-width: 200px; 
}

.insurance-claim-container .status-filter {
    width: 100%;
    font-size: 14px; 
    height: 38px;
}

.insurance-claim-container .insurance-claim-global-search .p-inputtext {
    height: 40px; 
    width: 95%;
}

.insurance-claim-container .error-message {
    color: red;
    font-size: 12px;
    padding-top: 5px;
}

@media (max-width: 768px) {
    .insurance-claim-container .insurance-claim-col {
        padding: 5px;
    }

    .insurance-claim-container .filter-dropdown, 
    .insurance-claim-container .filter-date-picker, 
    .insurance-claim-container .filter-search {
        max-width: none;
    }
}

.insurance-claim-table-dropdown .p-dropdown .p-dropdown-label.p-placeholder {
    padding: 5px;
}

.insurance-claim-global-search .p-inputgroup {
    display: flex;
    align-items: center;
    width: 100%; /* Adjust based on the container or leave it responsive */
  }
  
  .insurance-claim-global-search .p-inputgroup-addon {
    background-color: #eee;
    height: 40px;
    width: 4px;
    margin-left: 5px;
  }
  
  .insurance-claim-global-search .p-inputtext {
    flex: 1; /* Ensures the input text expands to fill space */
  }
  
  .insurance-claim-global-search .p-inputtext:focus {
    box-shadow: none; /* Removes the default focus shadow */
  }
  
  /* You might want to adjust the icon size and alignment */
  .insurance-claim-global-search .p-inputgroup-addon .pi {
    margin: 0.5rem; /* Center the icon vertically and provide some padding */
  }
  