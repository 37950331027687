.additions-row {
  color: red !important;
}

.subtractions-row {
  color: blue !important;
}

.claimed-row {
  color: white !important;
  background-color: #a2cbfa !important;
}

.referral-row {
  color: #4A0000 !important;
  font-weight: bold;
}

.rejected-row {
  color: white !important;
  background-color: #de7979 !important;
}