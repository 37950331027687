.container {
  max-width: unset !important;
  padding: 0.5rem 1.5rem;
  @media screen and (min-width: 768px) {
    width: 100%;
    // max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 80px;
  // min-height: calc(100vh - 120px);
  min-height: calc(100vh - 60px);
  transition: padding-left 0.3s;
  // overflow: hidden;
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 200px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.patient-nav-bar-main{
  .copy-btn {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: all 0.3s ease-in-out;
    border: none;
  }
  
  .copy-btn-icon {
    font-size: 1rem;
    transition: transform 0.3s ease;
  }
}
