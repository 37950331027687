
$mic-color: #ff4c4c;
$mic-hover-color: #ff6a6a;
$wave-color: rgba(255, 76, 76, 0.4);

.wave-mic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 250px;
}

.mic-button {
  background-color: $mic-color;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $mic-hover-color;
  }
}

.wave-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;


  .wave {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid $wave-color;
    border-radius: 50%;
    animation: wave-expand 2s cubic-bezier(0.25, 1, 0.5, 1) infinite;

    // Delays for each wave
    &.wave-2 {
      animation-delay: 0.5s;
    }
    &.wave-3 {
      animation-delay: 1s;
    }
  }
}

// Keyframes for smooth wave expansion
@keyframes wave-expand {
  0% {
    transform: scale(0.5);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.4;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

