.dynamic-image-class{
  display: none;
}
.image-uploads-wrapper .image-grid {
  display: grid;
  grid-template-columns: repeat(3, 230px);
  justify-content: center;
}

.image-uploads-single .image-grid {
  display: grid;
  grid-template-columns: repeat(2, 230px);
  justify-content: center;
  margin-right: 230px;
}


.image-uploads-single .image-item {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}
.image-uploads-wrapper .image-item {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.image-uploads-specific {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-uploads-specific .dropzone {
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
 
.image-uploads-specific .preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-uploads-specific .preview-image {
  height: 100%;
  width: 100%; 
  object-fit: cover; 
}


.icon-container {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-background {
  background-color: rgba(0, 0, 0, 0.6); /* Default background color */
  padding: 4px;
  border-radius: 6px;
  margin-left: 4px; /* Spacing between icons */
}

.remove-icon, .rotate-icon {
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  transition: transform 0.2s, color 0.2s;
}

.remove-icon:hover, .rotate-icon:hover {
  transform: scale(1.15);
  color: #e0e0e0;
}

/* Individual icon background colors */
.remove-icon {
  background-color: rgba(0, 0, 0, 0.6); 
}

.rotate-icon {
  background-color: rgba(0, 0, 0, 0.6);
}

.image-container img {
  transition: transform 0.3s ease-in-out;
}

.single-upload-image-container img {
  transition: transform 0.3s ease-in-out;
  height: 80%;
  width: 49%;
}
.image-item .patient-name {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 15px;
  padding-left: 2px;
  padding-right: 2px;
  max-width: 95%; 
}

.image-item .patient-name span {
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}


.image-uploads-specific .preview {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}



.ip-image {
  height: 100%;
}

svg.svg-inline--fa.fa-image.fa-w-16.ip-image {
  width: 40px;
  height: 40px;
}

.dropzone-content p.drop-text {
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 15px;
}
.dropzone-content p.browse-text {
  padding: 10px 20px;
  background-color: #3c6177; 
  color: #FFFFFF;
  font-weight: bold; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: background-color 0.3s ease;
  display: inline-block; 
  text-align: center; 
}

.dropzone-content p.browse-text:hover {
  background-color: #5a8399;
}

.image-uploads-specific .image-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); 
  color: black;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  box-sizing: border-box;
}

.dropzone-content .box-heading {
  text-align: center;
  margin-top: 0;
  font-weight: bold; 
  margin-bottom: 30px; 
}

.image-uploads-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  flex-grow: 1;
}

.image-item {
  flex-basis: 30%; 
  margin-bottom: 10px;
}

.control-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

/* Custom Dialog Styling */
.image-dialog .dialog {
  background-color: #fff;
  width: 300px; /* Adjust width as necessary */
  margin: auto;
}

.image-dialog .content {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #333;
}

.image-dialog .footer-common {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.image-dialog .footer-webcam {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 20px;
  border-top: 1px solid #ccc;  /* Subtle divider */
}
.image-dialog .button {
  font-size: 16px;
  color: #007aff;
  background: none;
  border: 1px solid #007aff;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.image-dialog .button:hover {
  background-color: #f0f0f5;
}

.image-dialog .icon {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
}

.webcam-buttons {
  right: 0;
  bottom: 10px; 
  padding-left: 20px; 
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.icon-controls {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.icon-controls svg {
  margin: 0 15px;
  cursor: pointer;
  color: #3c6177;
  font-size: 32px;
  transition: background-color 0.3s, transform 0.3s; // Smooth transition for background color and hover effects
}

.icon-controls svg:hover {
  color: #5a8399;
  transform: scale(1.1); // Slight increase in size on hover for better interaction feedback
}

.icon-selected {
  background-color: #e6f4f1; // Using a very light background color for subtle highlighting
  color: #3c6177; // Keeping the icon color constant for better visibility
  border-radius: 50%; // Circular background
  padding: 5px; // Minimal padding to avoid shrinking the icon
}
.single-upload{
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
}
.orthoform-wrapper {
  justify-content: center;
}

.custom-dropzone-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 500px;   
  height: 250px; 
  margin: auto;
}

.add-dropzone-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 24px;
  color: #3c6177;
}

.add-dropzone-icon:hover {
  color: #5a8399;
}

.icon-buttons-container {
  display: flex;
  gap: 10px; // Adjust spacing as needed
  padding: 10px;
}

.ortho-form .preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ortho-form .preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ortho-form-image {
  width: 100%; 
  height: auto;
  object-fit: contain;
  border-radius: 4px; 
  transition: transform 0.3s ease-in-out; /* Smooth rotation */
  transform-origin: center; /* Ensure the rotation is around the center */
}
.dropzone-image-container .dropzone-image {
  width: 100%;  
  height: auto; 
  object-fit: contain;
  border-radius: 4px;
}   

.treatment-status-container {
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-right: 240px;
}

.treatment-status-label {
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}

.treatment-status-label .required-asterisk {
  color: red;
}
