.phone-field-component .country-code-dropdown  {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    width: 25% !important;
    height: 52px;
}

.phone-field-component .flagIcon {
  height: 15px;
  width: 30px;
  border-radius: 0;
  margin-top: 0 !important;
}
.phone-field-component .country-code-dropdown .p-dropdown {
    border: none;
    margin-right: -1px;
    border-radius: 0;
    width: 100% !important;
}

.phone-field-component .country-code-dropdown .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 1.5rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 1rem;
}

.phone-field-component .phone-input {
    display: flex;
    align-items: flex-end;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    height: 52px;
}

.phone-field-component .phone-input  .p-inputtext:enabled:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: none !important;
}
  
  .country-code-dropdown .p-dropdown {
    border: none; // Remove individual borders
    margin-right: -1px; // Pulls the dropdown visually into the input field
    border-radius: 0; // Resets the border-radius on the dropdown
  }
  
  .phone-field-component .phone-number-input .p-inputtext {
    border: none;
    padding: 5px;
}
  
  .phone-field-component .phone-number-input .p-dropdown .p-dropdown-label,
  .phone-field-component .p-inputtext {
    //   padding: 2px;
  }
  
 .phone-input .p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none;
}

.phone-input .p-dropdown-label .p-inputtext .p-placeholder{
    width: 20%;
}

.phone-field-component .country-code-dropdown .p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 5px;
}