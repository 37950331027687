.charts {
    background-color: #fff;
    // padding: 20px;
    width: 100%;
    height: 700px;
}

.charts_tabs {
    flex-grow: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #ccc;
    margin: 5px;
    display: flex;
    height: 680px;
    overflow-y: auto;
    padding: 10px 0;

    .charts_tabs_inner {
        flex-grow: 1;
        overflow-y: auto;
        font-size: 16px;
    }
}

.display_teeth_header {
    flex-shrink: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #ccc;
    padding: 0 10px;
    margin: 5px;
    height: 680px;
}

.tooth_img {
    width: 98%;
    height: 98%;
    object-fit: cover;
    // padding: 1px;
}

.tooth_num {
    display: flex;
    justify-content: center;
    font-size: larger;
    text-align: center;
    margin: 5px 0 5px 0;

    .selected {
        background-color: rgb(241, 73, 73);
        color: #fff;
        width: 25px;
        border-radius: 50%;
    }
}



/* Data table styling */
.data-table {
    flex-grow: 1;
    // padding-right: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.text-center {
    text-align: center;
}

/* Priority table styling */
.priority-table {
    width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.listBox {
    border: 1px solid black;
    margin-top: 10px;
}

.priority-table label {
    font-weight: bold;
}

.priority-table .p-listbox .p-listbox-list .p-listbox-item {
    padding: 0.35rem 1.25rem !important;
}

.quick_action_buttons {
    border-left: 2px solid #ccc;
    height: 100%;

    .quick_button_listbox {
        width: 100%;
        // display: flex;
        // justify-content: center;
        text-align: left;

        label {
            font-weight: bold;
        }
    }
}