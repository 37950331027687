.periocharts {
    position: relative;
    height: calc(100vh - 150px);
    overflow-y: auto;
    background-color: white;
    padding: 0px 12px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.display_inputfields .teeth-input-fields {
    border: 1px solid black;
    padding: 0px;
}

.heading {
    font-weight: bold;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.periocharts {

    .display_datatable,
    .display_inputfields {
        width: 100%;
    }
}

@media (min-width: 1100px) {
    .periocharts {
        .display_datatable {
            width: 30%;
        }

        .display_inputfields {
            width: 70%;
        }
    }
}

.teeth-display-row,
.teeth-display-row-inputs {
    display: flex;
    width: auto;

    .teeth-missed {
        background-color: #dedede;
    }
}

.teeth-display-row {

    .teeth-input-card-header,
    .teeth-input-card {
        background-color: #dedede;
    }
}

.num {
    border: 1px solid black;
    font-weight: bold;

    .teeth-input-card-header {
        padding: 0px;
        border-right: 1px solid black;
    }
}

.teeth-input-card-header,
.teeth-input-card {
    height: 30px;
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
}


.teeth-input-card-header {
    width: 12rem;
    font-weight: bold;
    text-align: right;
    border: 1px solid black;
    padding-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.teeth-input-card {
    width: 6rem;
    text-align: center;
    justify-content: space-evenly;
    font-size: large;

    .teeth-number-selected {
        background-color: red;
        border-radius: 100%;
        color: white;
        height: 25px;
        width: 25px;
    }
}

.diasble-teeth-input-card {
    background-color: rgb(158, 137, 109);
}


.teeth-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .p-inputnumber .p-inputnumber-input {
        padding: 0px;
        width: 25%;
        height: 100%;
        border: none;
        background-color: transparent;
        font-size: large;
        text-align: center;
        // color: black;

        &:focus {
            outline: none;
            text-align: center;
            background-color: rgb(239, 164, 137);
            color: black !important;
            caret-color: transparent; // Hide the caret 
        }
    }

    .red input {
        color: red !important;
        // font-weight: bold;
    }
}